import ApiService from "@/assets/js/services/api.service";
import settings from "../../assets/js/settings";

const state = {
  user: {
    id: "",
    name: "",
    company: "",
    email: "",
    rating: "",
    resourceId: "",
    balance: 0,
    donated: 0,
    goodsGive: 0,
    goodsTake: 0
  },
  partner: {
    id: "",
    title: "",
    logoResourceId: "",
    color: "",
    categoryName: "",
    categoryId: "",
    goodsCategoryName: "",
    goodsCategoryId: "",
    pointsCode: "",
    description: "",
    resourceIds: [],
    geos: ""
  },
  providers: [],

  token: localStorage.getItem("id_token") || "",
  status: "",
  loginIsValid: null
};
const getters = {
  user: state => state.user,
  partner: state => state.partner,
  providers: state => state.providers,
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  loginIsValid: state => state.loginIsValid // можно отрефакторить на промис, но будет куда больше кода тут и внутри компонента. мне кажется, так куда проще и понятнее
};
const actions = {
  signIn: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      let body = {
        identifierValue: data.login,
        identifierTypeId: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
        password: data.password
      };
      commit("authState", { status: "processing" });
      ApiService.post("/platform/api/auth/token/sign-in", body)
        .then(response => {
          localStorage.setItem("id_token", response.data.token);
          ApiService.setHeader();
          commit("authState", { status: "success" });
          resolve(response);
        })
        .catch(({ response }) => {
          commit("authState", { status: "error" });
          localStorage.removeItem("id_token");
          reject(response);
        });
    });
  },
  signUp({ commit }, data) {
    let body = {
      identifiers: [
        {
          value: data.login,
          identifierTypeId: "54480ce1-00eb-4179-a2b6-f74daa6b9e72"
        }
      ],
      password: data.password,
      fields: {
        name: data.name
      }
    };
    return new Promise((resolve, reject) => {
      ApiService.post("/platform/api/user/sign-up", body)
        .then(result => {
          if (result.data.success) {
            resolve(result.data.id);
          } else {
            reject();
          }
        })
        .catch(({ result }) => {
          reject(result);
        });
    });
  },
  sendVerificationCodeForSignIn: ({ commit }, body) => {
    return ApiService.post("platform/api/identifier/verify", body);
  },
  sendVerificationCodeForPasswordChanging: ({ commit }, login) => {
    let body = {
      identifierValue: login,
      identifierTypeId: "54480ce1-00eb-4179-a2b6-f74daa6b9e72"
    };
    return ApiService.post(
      "platform/api/auth/send-verification-code-for-password-changing",
      body
    );
  },
  changePassword: ({ commit }, body) => {
    return ApiService.post(
      "platform/api/auth/change-password-via-verification-code",
      body
    );
  },
  logout: ({ commit }) => {
    commit("authState", { status: "" });
    localStorage.removeItem("id_token");
    setTimeout(() => {
      commit("setUser", {
        props: "",
        id: "",
        name: "",
        company: "",
        rating: "",
        email: "",
        resourceId: ""
      });
      commit("setPartner", {
        id: "",
        title: "",
        logoResourceId: "",
        color: "",
        categoryName: "",
        categoryId: "",
        goodsCategoryName: "",
        goodsCategoryId: "",
        pointsCode: "",
        description: "",
        resourceIds: [],
        geos: ""
      });
    }, settings.__animationSpeed);
  },
  checkLogin({ commit }, login) {
    commit("setLoginIsValid", { valid: null });
    let body = {
      identifierValue: login,
      identifierTypeId: "54480ce1-00eb-4179-a2b6-f74daa6b9e72"
    };
    return new Promise((resolve, reject) => {
      ApiService.post(
        "/platform/api/identifier/validate?checkAvailability=true",
        body
      )
        .then(result => {
          commit("setLoginIsValid", result.data);
          if (result.data.valid === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(({ result }) => {
          reject(result.data);
        });
    });
  },
  loadUserInfo({ commit, getters, dispatch }) {
    if (!getters.isAuthenticated) {
      return;
    }
    ApiService.get("/api/client/user").then(result => {
      commit("setUser", {
        props: result.data,
        id: result.data.id,
        name: result.data.name,
        company: result.data.company,
        rating: result.data.rating,
        email: result.data.email,
        resourceId: result.data.resourceId,
        balance: result.data.balance,
        donated: result.data.donated,
        goodsGive: result.data.goodsGive,
        goodsTake: result.data.goodsTake,
        type: result.data.type,
        partnerId: result.data.partnerId
      });
      if (result.data.partnerId && result.data.partnerId !== "") {
        dispatch("loadPartnerInfo", { id: result.data.partnerId });
        dispatch("loadSpecialsAndCommit", { id: result.data.partnerId });
      }
    });
  },
  loadPartnerInfo({ dispatch, commit, getters }, params) {
    ApiService.get("api/client/partners/" + params.id).then(result => {
      commit("setPartner", {
        id: result.data.id,
        title: result.data.title,
        logoResourceId: result.data.logoResourceId,
        color: result.data.color,
        categoryName: result.data.category.name,
        categoryId: result.data.category.id,
        goodsCategoryName: result.data.goodsCategory.name,
        goodsCategoryId: result.data.goodsCategory.id,
        pointsCode: result.data.pointsCode,
        description: result.data.description,
        geos: result.data.geos,
        contacts: result.data.contacts,
        resourceIds: result.data.resourceIds
      });
    });
  },
  loadOAuthProviders: ({ commit }) => {
    ApiService.get(
      "/platform/api/oauth/providers?redirectUri=" +
        window.location.origin +
        "/oauth/"
    ).then(result => {
      commit("setProviders", result.data);
    });
  },
  singInWithOAuthCode: ({ commit }, body) => {
    return new Promise((resolve, reject) => {
      ApiService.post("/platform/api/oauth/token-by-code", body)
        .then(response => {
          localStorage.setItem("id_token", response.data.token);
          ApiService.setHeader();
          resolve(true);
        })
        .catch(result => {
          reject(result);
        });
    });
  }
};

const mutations = {
  authState: (state, payload) => {
    state.status = payload.status;
  },
  setLoginIsValid: (state, payload) => {
    state.loginIsValid = payload.valid;
  },
  setUser: (state, payload) => {
    state.user.id = payload.id;
    state.user.name = payload.name;
    state.user.company = payload.company;
    state.user.email = payload.email;
    state.user.rating = payload.rating;
    state.user.resourceId = payload.resourceId;
    state.user.balance = payload.balance;
    state.user.donated = payload.donated;
    state.user.goodsGive = payload.goodsGive;
    state.user.goodsTake = payload.goodsTake;
    state.user.type = payload.type;
    state.user.partnerId = payload.partnerId;
  },
  setPartner: (state, payload) => {
    state.partner.id = payload.id;
    state.partner.title = payload.title;
    state.partner.logoResourceId = payload.logoResourceId;
    state.partner.color = payload.color;
    state.partner.categoryName = payload.categoryName;
    state.partner.categoryId = payload.categoryId;
    state.partner.goodsCategoryName = payload.goodsCategoryName;
    state.partner.goodsCategoryId = payload.goodsCategoryId;
    state.partner.pointsCode = payload.pointsCode;
    state.partner.description = payload.description;
    state.partner.geos = payload.geos;
    state.partner.contacts = payload.contacts;
    state.partner.resourceIds = payload.resourceIds;
  },
  setUserResourceId: (state, payload) => {
    state.user.resourceId = payload.resourceId;
  },
  setProviders: (state, payload) => {
    state.providers.splice(0, state.providers.length);
    state.providers.push(...payload);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
