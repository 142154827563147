<template>
  <div class="holder wrap">
    <a href="" id="logo" @click.prevent="goToMain"><img src="../../public/images/logo.svg" :alt="$t('header.for-free')"
                                                 :title="$t('header.for-free')"></a>
    <a id="add-item" @click="showLoginModal"><span>{{ $t("header.add-item") }}</span></a>
    <a class="btn " id="btn-login" ref="loginBtn" @click="showLoginModal">{{ $t("header.sign-in") }}</a>
  </div>
</template>

<script>

  import modal from '../assets/js/init/modal';
  import { eventBus } from '../main';

  export default {
    name: 'DefaultHeader',
    data() {
      return {
        codeWaiting: false,
      };
    },
    created() {
      eventBus.$on('codeWaiting', data => this.codeWaiting = data);
      eventBus.$on('showLoginModal', data => this.showLoginModal());
    },
    methods: {
      goToMain() {
        window.scrollTo(0, 0);
        this.$router.push({ name: 'objects' });
      },
      showLoginModal() {
        if(this.codeWaiting) {
          modal.openModal('modal-confirmation-code');
        } else {
          eventBus.$emit('onLoginModalOpen');
        }
      }
    }
  };
</script>

<style scoped>

</style>
